.background-shape-container {
	position: absolute;
	z-index: -1;

	width: 100%;
	overflow: hidden;
	
	&-aligned-center {
		transform: translateY(-50%);

	}

	&-aligned-bottom {
		transform: translateY(-100%);

	}

	.background-shape {
		position: relative;
		
		max-height: 960px;

		margin-left: auto;
		margin-right: auto;
		
		&:after {
			content: " ";
			display: block;

			background: 50%/contain no-repeat;

		}

		&:before {
			content: " ";
			display: block;

			position: absolute;
			width: 100%;

			@apply bg-warmgray;

		}

	}

    .background-shape-diagonal:after {
        @apply bg-warmgray;

        padding-bottom: 600px;

        transform-origin: 0 0;
        transform: skew(-23deg);
        width: 88.5%;

        @screen sm {
            width: calc(50vw + 128px);

        }
        @screen md {
            width: calc(50vw + 160px);

        }
        @screen lg {
            width: calc(50vw + 222px);

        }
        @screen xl {
            width: calc(50vw + 316px);

        }

    }

	.background-shape {
		&-tubag {
			&:after {
				@apply bg-warmgray;

				padding-bottom: 600px;

				transform-origin: 0 0;
				transform: skew(-23deg);

				/*
				width: calc(86vw - 10px);

				@screen sm {
					width: calc(50vw + 195px);

				}
				@screen md {
					width: calc(50vw + 251px);

				}
				@screen lg {
					width: calc(50vw + 341px);

				}
				@screen xl {
					width: calc(50vw + 488px);

				}
				*/
				width: 88.5%; // calc(72vw);

				@screen sm {
					width: calc(50vw + 128px);

				}
				@screen md {
					width: calc(50vw + 160px);

				}
				@screen lg {
					width: calc(50vw + 222px);

				}
				@screen xl {
					width: calc(50vw + 316px);

				}

			}

			&.background-shape-shifted-left:after {
				/*
				width: 88.5%; // calc(72vw);

				@screen sm {
					width: calc(50vw + 128px);

				}
				@screen md {
					width: calc(50vw + 160px);

				}
				@screen lg {
					width: calc(50vw + 222px);

				}
				@screen xl {
					width: calc(50vw + 316px);

				}
				*/

				width: 50%;

				@screen sm {
					width: calc(50vw - 5px);

				}
				@screen md {
					width: calc(50vw - 86px);

				}
				@screen lg {
					width: calc(50vw - 206px);

				}
				@screen xl {
					width: calc(50vw - 405px);

				}

			}

			&.background-shape-shifted-right:after {
				/*
				width: calc(100vw + 90px); // calc(100vw - 20px);

				@screen sm {
					width: calc(50vw + 262px);

				}
				@screen md {
					width: calc(50vw + 342px);

				}
				@screen lg {
					width: calc(50vw + 460px);

				}
				@screen xl {
					width: calc(50vw + 660px);

				}
				*/

				width: calc(100vw - 20px);

				@screen md {
					width: calc(100vw - 40px);
					min-width: calc(50vw + 342px);

				}
				@screen lg {
					width: calc(100vw - 60px);
					min-width: calc(50vw + 460px);

				}
				@screen xl {
					width: calc(100vw - 100px);
					min-width: calc(50vw + 660px);
					max-width: calc(50vw + 916px);

				}

			}

			&.background-shape-aligned-right {
				&:after {
					transform-origin: 100% 100%;

					margin-left: auto;

				}

				&.background-shape-shifted-left:after {
					/*
					width: calc(100vw - 20px);

					@screen sm {
						width: calc(50vw + 262px);

					}
					@screen md {
						width: calc(50vw + 342px);

					}
					@screen lg {
						width: calc(50vw + 460px);

					}
					@screen xl {
						width: calc(50vw + 660px);

					}
					*/

					width: calc(100vw - 20px);

					@screen md {
						width: calc(100vw - 40px);
						min-width: calc(50vw + 342px);

					}
					@screen lg {
						width: calc(100vw - 60px);
						min-width: calc(50vw + 460px);

					}
					@screen xl {
						width: calc(100vw - 100px);
						min-width: calc(50vw + 660px);
						max-width: calc(50vw + 916px);

					}

				}

				&.background-shape-shifted-right:after {
					/*
					width: calc(72vw);

					@screen sm {
						width: calc(50vw + 128px);

					}
					@screen md {
						width: calc(50vw + 160px);

					}
					@screen lg {
						width: calc(50vw + 222px);

					}
					@screen xl {
						width: calc(50vw + 316px);

					}
					*/

					width: 50%;
					min-width: 0;
					max-width: none;

					@screen sm {
						width: calc(50vw - 5px);

					}
					@screen md {
						width: calc(50vw - 86px);

					}
					@screen lg {
						width: calc(50vw - 206px);

					}
					@screen xl {
						width: calc(50vw - 405px);

					}

				}

			}

		}

	}

	&-teaser {
		&.background-shape-container-aligned-center {
			transform: translateY(calc(-100% + 60px));

			@screen lg {
				transform: translateY(-75%);

			}

		}

		.background-shape {
			&:after {
				padding-bottom: 0;
				height: 600px;
				max-height: 600px;

				@screen lg {
					height: 500px;

				}

				@screen xl {
					height: 600px;

				}

			}

			/*
			&.background-shape-shifted-left:after {
				width: calc(100vw - 20px);

				@screen sm {
					width: calc(50vw + 262px);

				}
				@screen md {
					width: calc(50vw + 342px);

				}
				@screen lg {
					width: calc(50vw + 460px);

				}
				@screen xl {
					width: calc(50vw + 660px);

				}

			}
			*/

		}

	}

}

.pr-background-shape {
    position: relative;
    overflow: hidden;

    .background-shape-image > *:not(.class) {
        &:after {
            content: " ";
            position: absolute;
            z-index: 1;

            @apply inset-0;
            right: -100vw;
            @apply bg-tubag-red;

            transform-origin: 100% 100%;
            transform: skew(-23deg);

            left: 60vw;

        }

		display: none;

        + * {
            display: block;

        }

    }

    &.pr-background-shape-image {
        .background-shape-image {
            &.background-shape-image--left > *:after {
                left: -100vw;
                right: 90vw;
            }
            & > *:after {
                left: 70vw;
            }
            &.ref-background-shape > *:after {
                left: 50vw;
            }

        }

		figure {
			position: relative;
			z-index: 2;

			margin-top: 50px;
			margin-bottom: 50px;

		}

		margin-bottom: 45px;

		@screen md {
			figure {
				margin-top: 65px;
				margin-bottom: 65px;

			}

		}
		@screen xl {
			figure {
				margin-top: 90px;
				margin-bottom: 0;

			}

			margin-bottom: 90px;

			.background-shape-image > *:after {
				bottom: 125px;

			}

		}

    }

    .background-shape-image {
        &.background-shape-image--left > *:after {
            left: -100vw;
            right: 80vw;
        }
        &.background-shape-image--gray > *:after {
            @apply bg-warmgray;
        }
        &.background-shape-image--left.background-shape-image--gray > *:after {
            transform-origin: 100% 0%;
            right: 50vw;
        }
        &.background-shape-image--gray > *:after {
            left: 50vw;
        }

    }

    &.pr-background-shape-image-empty {
		overflow: visible;
		height: 0;
		z-index: -1;

		> div > div {
			position: relative;
			overflow: hidden;

			height: 100vw;

			@media (min-width: 379px) {
				height: 379px;

			}
			@screen md {
				height: 480px;

			}
			@screen lg {
				height: 560px;

			}
			@screen xl {
				height: 708px;

			}

		}

		+ .container {
			margin-top: 60px;

			@screen md {
				margin-top: 90px;

			}
			@screen xl {
				margin-top: 150px;

			}

		}

    }

}
