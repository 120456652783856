/**
 * Product Detail Page
 *
 */

.product-detail-page {
    /* extensions to header-image */
    .header-image {

        .background-shape {
            height: 60vw;

            @screen sm {
                height: 335px;
            }

            @screen md {
                height: 620px;
            }

            @screen lg {
                height: 560px;
            }

            @screen xl {
                height: 708px;
            }
        }

        .background-shape-image {
            display: block;
        }

        .background-shape-image--left {

            > * {
                &:after {
                    content: " ";
                    right: calc(40vw - 10px);

                    @screen md {
                        right: calc(50vw + 100px);
                    }

                    @screen lg {
                        right: calc(50vw + 124px);
                    }
                }
            }
        }
    }

}
