@tailwind base;
@tailwind components;

/**
 * Available path aliases: @sievert & @tubag
 * for the asset directories in project
 */
@import "@sievert/Styles/_variables";
@import "@tubag/Styles/_variables";
@import "@tubag/Styles/_primary-color";
@import "@sievert/Styles/_base-fx";
/*! purgecss start ignore */
@import "@tubag/Styles/_typography";
@import "@tubag/Styles/_interaction";
@import "@sievert/Styles/_ui";
@import "@tubag/Styles/_ui";
@import "@sievert/Styles/_tables";
@import "@sievert/Styles/_images";
@import "@sievert/Styles/_forms";
@import "@sievert/Styles/_grid";
@import "@sievert/Styles/_navigation";
@import "@sievert/Styles/_navigation-brands";
@import "@tubag/Styles/_navigation";
@import "@tubag/Styles/_footer";
@import "@tubag/Styles/_background-shapes";
@import "@sievert/Styles/_campaign";
@import "@tubag/Styles/_campaign";
@import "@tubag/Styles/_header-image";
@import "@sievert/Styles/_accordion";
@import "@tubag/Styles/_accordion";
@import "@sievert/Styles/_gallery";
@import "@tubag/Styles/_gallery";
@import "@sievert/Styles/_downloadList";
@import "@tubag/Styles/_downloadList";
@import "@sievert/Styles/_collage";
@import "@sievert/Styles/_video";
@import "@tubag/Styles/_sievert-logo-square";
@import "@sievert/Styles/_richtext";
@import "@tubag/Styles/_richtext-overrides";
@import "@tubag/Styles/_application-finder";
@import "@sievert/Styles/_product-detail-page";
@import "@tubag/Styles/_product-detail-page";
@import "@tubag/Styles/_product-overview-page";
@import "@sievert/Styles/_typography-campaign";

/*! purgecss end ignore */

// @import "@sievert/Styles/_grid-debug";

@tailwind utilities;
