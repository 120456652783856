.header-image {
	position: relative;
	z-index: 0;
	
	margin-top: 96px;
	
	@screen lg {
		margin-top: 99px;
		
	}
	
	.background-shape {
		position: relative;
		overflow: hidden;
		
		height: 100vw;
		
		@media (min-width: 379px) {
			height: 379px;
			
		}
		@screen md {
			height: 480px;
			
		}
		@screen lg {
			height: 560px;
			
		}
		@screen xl {
			height: 708px;

		}
		
	}
	
	.background-shape-image {
		pointer-events: none;
		
		> * {
			position: absolute;
			z-index: 16;
			@apply inset-0;
			
			mix-blend-mode: multiply;
			
			+ * {
				mix-blend-mode: normal;
				
			}
			
			&:after {
				content: " ";
				position: absolute;
				z-index: 1;

				@apply inset-0;
				@apply bg-tubag-red;
				
				transform: skew(-23deg);
				
			}
			
		}
		
		+ [data-class-name=ImageSequencer] {
			picture {
				position: absolute;
				@apply inset-0;
				
				img {
					position: absolute;
					@apply inset-0;
					height: 100%;
					
					object-fit: cover;
					object-position: 75% 50%;
					
					width: 100%;
					
					@screen sm {
						width: calc(100vw - 55px);
						
					}
					@screen md {
						width: calc(50vw + 315px);
						
					}
					@screen lg {
						width: calc(50vw + 415px);
						
					}
					@screen xl {
						width: calc(50vw + 582px);
						
					}
					
				}
				
			}
			
		}
		
		&--left {
			> * {
				&:after {
					transform-origin: 0% 0%;
					
					left: -100vw;
					right: calc(100vw - 32vw);
					
					@media (min-width: 379px) {
						right: calc(100vw - 120px);
						
					}
					@screen sm {
						right: calc(100vw - 216px);
						
					}
					@screen md {
						right: calc(50vw + 108px);
						
					}
					@screen lg {
						right: calc(50vw + 175px);
						
					}
					@screen xl {
						right: calc(50vw + 280px);
						
					}
					
				}
				
			}
			
			+ [data-class-name=ImageSequencer] {
				picture {
					img {
						left: auto;
						
					}
					
				}
				
			}
			
		}
		
		&--right {
			> * {
				&:after {
					transform-origin: 100% 100%;
					
					right: -100vw;
					left: calc(100vw - 32vw);
					
					@media (min-width: 379px) {
						left: calc(100vw - 120px);
						
					}
					@screen sm {
						left: calc(100vw - 216px);
						
					}
					@screen md {
						left: calc(50vw + 108px);
						
					}
					@screen lg {
						left: calc(50vw + 175px);
						
					}
					@screen xl {
						left: calc(50vw + 280px);
						
					}
					
				}
				
			}
			
		}
		
	}
	
    + div > main {
		position: relative;
		
		@apply mt-60;
		
		@screen md {
			@apply mt-90;
			
		}
		@screen xl {
			@apply mt-150;
			
		}
		
    }
    
	&--no-images {
		height: 0;
		
		&.header-image--shifted-up {
			margin-bottom: -75px;
			
			@screen lg {
				margin-bottom: -100px;
				
			}
			
		}
		
		.background-shape-image {
			> * {
				display: none;
				
				+ * {
					display: block;
					
				}
				
			}
			
			&--left {
				> * {
					&:after {
						content: none;
						
						@screen sm {
							content: " ";
							right: calc(50vw + 220px);
							
						}
						@screen md {
							right: calc(50vw + 235px);
							
						}
						@screen lg {
							right: calc(50vw + 315px);
							
						}
						@screen xl {
							right: calc(50vw + 460px);
							
						}
						
					}
					
				}
				
			}
			
			&--right {
				> * {
					&:after {
						content: none;
						
						@screen sm {
							content: " ";
							left: calc(50vw + 220px);
							
						}
						@screen md {
							left: calc(50vw + 150px);
							
						}
						@screen lg {
							left: calc(50vw + 250px);
							
						}
						@screen xl {
							left: calc(50vw + 380px);
							
						}
						
					}
					
				}
				
			}
			
			&--gray {
				> * {
					&:after {
						content: " ";
						
						@apply bg-warmgray;
						
					}
					
				}
				
				&.background-shape-image--left {
					> * {
						&:after {
							right: 50vw;
							
						}
						
					}
					
				}
				
				&.background-shape-image--right {
					> * {
						&:after {
							left: 50vw;
							
						}
						
					}
					
				}
				
			}
			
		}
		
		+ div > main {
			margin-top: -50px;
			
			@screen md {
				margin-top: -100px;
				
			}
			@screen lg {
				margin-top: -50px;
				
			}
			
		}

	}
    
}
