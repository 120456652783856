@import "@sievert/Styles/_footer-brands";

footer.section {
	> .container {
		&:before {
			@screen xl {
				background: url("#{$img-root-tubag}/footer/background-shape.svg") 100% 0/cover no-repeat;
				
			}
			
		}
		
	}
	
	.footer-links-column {
		@apply .text-white;
		
		&:before {
			background: url("#{$img-root-tubag}/footer/background-shape.svg") 100% 0/cover no-repeat;
			
		}
		
		.footer-legal-social {
			@apply text-white;
			
			.footer-social-links {
				.footer-social-link {
					&-linked-in {
						background-image: url("#{$img-root}/footer/social-icon-linked-in-white.svg");
						
					}
					
					&-xing {
						background-image: url("#{$img-root}/footer/social-icon-xing-white.svg");
						
					}
					
					&-facebook {
						background-image: url("#{$img-root}/footer/social-icon-facebook-white.svg");
						
					}
					
					&-instagram {
						background-image: url("#{$img-root}/footer/social-icon-instagram-white.svg");
						
					}
					
					&-you-tube {
						background-image: url("#{$img-root}/footer/social-icon-you-tube-white.svg");
						
					}
					
				}
				
			}
			
		}
		
	}
	
}
