body.campaign-page {
	.campaign-background-shape {
		width: 175%;
		left: -10%;
		
		@screen sm {
			left: auto;
			right: 0;
			width: 72.9444444444444%;
			
		}
		
		max-width: 1800px - 490px;
		
		&:after {
			padding-bottom: 235.8149138646979%;
			background: url("#{$img-root-tubag}/campaign/background-1800.svg") 100% 0%/contain no-repeat;
			
		}
		
	}
	
}
